/* ======================  SCRIPTS ====================== */
/************  JQUERY EST DISPONIBLE ICI *****************/

var nbChampVide = 0;

$('document').ready(setup);
$(window).on('load', documentReady);

function setup() {
    initMatomo();
    initTarteAuCitron();
    $(window).scroll(showHideTelecommandAndStickyMenu);
    $('.navigation .burger').click(showHideResponsiveMenu);
    $('.navigation-lang').click(showHideLanguage);
    $('.nav-menu > .menu-item-has-children a').click(showSubmenuResponsive);
    $('.rangepage-subcategories input').change(updateProductList);
    $(".blockfactories-listwrapper").mCustomScrollbar();
    $('.blockfactories-listwrapper ul li').click(showClusterListInfos);
    $('.openModalDownload').click(showModalDownloadForm);
    $('.wpcf7-form .btn-main').click(testerFormulaire);
    $('.topage').click(scrollTopPage);
    initLeafletMap();
    detectMenuToActive();
}

function documentReady() {
    initSlickSlider();
    teamWorldMapEvents();
}

function initTarteAuCitron() {

    if(window.location.href.indexOf('/fr') !== -1) {
        var currentLanguage = 'fr';
    }else if (window.location.href.indexOf('/es') !== -1) {
        var currentLanguage = 'es';
    }else{
        var currentLanguage = 'en';
    }

    window.tarteaucitronForceLanguage = currentLanguage;

    tarteaucitron.init({
        "hashtag": "#tarteaucitron", /* Ouverture automatique du panel avec le hashtag */
        "DenyAllCta" : true, /* Show the deny all button */
        "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": true, /* désactiver le consentement implicite (en naviguant) ? */
        "orientation": "middle", /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
        "showAlertSmall": false, /* afficher le petit bandeau en bas à droite ? */
        "closePopup": false, /* Show a close X on the banner */
        "showIcon": false, /* Show cookie icon to manage cookies */
        "adblocker": false, /* Show a Warning if an adblocker is detected */
        "removeCredit": true, /* supprimer le lien vers la source ? */
        "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
        "moreInfoLink": true, /* Show more info link */
        "readmoreLink": "", /* Change the default readmore link */
        "gaTimeout":800
    });

    (tarteaucitron.job = tarteaucitron.job || []).push('youtube');

}

function initMatomo() {
    <!-- Matomo Tag Manager -->
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/legouessant.matomo.cloud/container_U9cnokmh.js';
    s.parentNode.insertBefore(g,s);
    <!-- End Matomo Tag Manager -->
}

// Fonction qui permet d'afficher/cacher
function showHideTelecommandAndStickyMenu() {
    if($('.homepage').length !== 0) {
        if($(this).scrollTop() >= 800 && $(this).scrollTop() < $('.footer').offset().top - 500){
            $('.telecommand').fadeIn(300);
        }else {
            $('.telecommand').fadeOut(300);
        }
    }else {
        if($(this).scrollTop() >= 400 && $(this).scrollTop() < $('.footer').offset().top - 500){
            $('.telecommand').fadeIn(300);
        }else {
            $('.telecommand').fadeOut(300);
        }
    }

    // Navigation Sticky
    if($(this).scrollTop() >= 300) {
        if($('.navigation.sticky').length === 0) {
            $('.header .navigation').clone().appendTo("body").addClass('sticky hide');
            $('.navigation.sticky .burger').click(showHideResponsiveMenu);
            $('.navigation.sticky .nav-menu > .menu-item-has-children a').click(showSubmenuResponsive);
        }else {
            $('.navigation.sticky').removeClass('hide');
        }
    }else {
        $('.navigation.sticky').addClass('hide');
    }
}

// Fonction qui permet de mettre en avant l'entrée du menu active
function detectMenuToActive() {
    // Si on est dans un menu de 2e niveau
    if($('.current-menu-item').length !== 0) {
        $('.current-menu-item').parent().parent().parent().parent().parent().addClass('current-menu-item');
    }

    // Si on est dans un menu de 3e niveau
    if($('.current-menu-item').parent().hasClass('sub-menu')) {
        $('.current-menu-item').parent().parent().parent().parent().parent().parent().parent().addClass('current-menu-item');
    }

    // Si on est sur la page News
    var currentUrl = window.location.href;
    if(currentUrl.indexOf('news') !== -1) {
        $('#menu-item-640').addClass('current-menu-item');
    }
}

// Fonction qui permet d'afficher/cacher le menu responsive
function showHideResponsiveMenu() {
    if($(this).hasClass('active')) {
        $(this).removeClass('active');
        $('.fadebg').removeClass('show');
        $(this).parent().children('.navigation-menu').children('.nav-menu').removeClass('open');
        $(this).parent().children('.navigation-menu').children('.utils').children('.navigation-searchbox').removeClass('show');
        $(this).parent().children('.navigation-mask').removeClass('open');
        $('html, body').removeAttr('style');
    }else {
        $(this).addClass('active');
        $('.fadebg').addClass('show');
        $(this).parent().children('.navigation-menu').children('.nav-menu').addClass('open');
        $(this).parent().children('.navigation-menu').children('.utils').children('.navigation-searchbox').addClass('show');
        $(this).parent().children('.navigation-mask').addClass('open');
        $('html, body').css('overflow', 'hidden');
    }
}

// Fonction qui permet de dérouler les sous menu en responsive
function showSubmenuResponsive(e) {
    if($(window).width() < 1200 && $(this).attr('href') === '#') e.preventDefault();
    if($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).parent().children('div').removeClass('open');
    }else {
        $(this).parent().addClass('active');
        $(this).parent().children('div').addClass('open');
    }
}

// Fonction qui permet d'afficher/cacher le menu des langues
function showHideLanguage() {
    if($('.navigation-lang .show').length === 0) {
        $('.navigation-lang li:not(.current)').addClass('show');
    }else {
        $('.navigation-lang li:not(.current)').removeClass('show');
    }
}

// Fonction qui permet d'initaliser les Sliders
function initSlickSlider() {
    $('.slidervisuals').slick({
        infinite: false,
        arrows: true,
        asNavFor: '.sliderthumbs',
        focusOnSelect: true
    });

    $('.sliderthumbs').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        centerMode: false,
        focusOnSelect: true
    });

    // Gestion de la navigation du slider
    $('.sliderthumbs').on("click", ".slick-slide", function () {
        if(!$(this).hasClass('slick-current')) {
            $('.slick-current').removeClass('slick-current');
            $(this).addClass('slick-current');
            var actIndex = $(this).attr('data-slick-index');
            var slider = $('.slidervisuals');
            slider[0].slick.slickGoTo(parseInt(actIndex));
        }
    });

    $('.blocktestimonies-slider').slick({
        infinite: false,
        arrows: true,
        adaptiveHeight: false
    });

    $('.blockrange-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: true,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 5000,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.blockcarrefour-blocks').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        centerMode: false,
        adaptiveHeight: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 5000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.blockteam-wrapper').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        centerMode: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 5000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.suggestproducts-content').slick({
        infinite: false,
        arrows: true,
        dots: false,
        adaptiveHeight: false,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    // Slick for species cards
    var settingsSpeciesCards = {
        infinite: false,
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    var speciesCardsSlick =  $('.speciesblock-elem .speciesblock-cards').slick(settingsSpeciesCards);

    // Slick for services cards
    var settingsServicesCards = {
        infinite: true,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    var servicesCardsSlick =  $('.services-list').slick(settingsServicesCards);

    // Slick for commitments cards
    var settingsCommitCards = {
        autoplay: true,
        infinite: true,
        arrows: true,
        dots: false,
        vertical: true,
        adaptiveHeight: true,
        verticalSwiping: true,
        focusOnSelect: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    var commitCardsSlick =  $('.commitments-cards').slick(settingsCommitCards);

    // Slick for news cards
    var settingsNewsCards = {
        arrows: true,
        dots: false,
        infinite: false,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    var newsCardsSlick =  $('.newsflexbox').slick(settingsNewsCards);

    // Slick for key numbers cards
    var settingsKeysCards = {
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    var keysCardsSlick =  $('.keynumbersblock-numbers').slick(settingsKeysCards);

    // Slick for suggested species cards
    var settingsSuggestSpeciesCards = {
        infinite: false,
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    var suggestSpeciesCardsSlick =  $('.suggestspecies-content').slick(settingsSuggestSpeciesCards);

    // Slick for Block Team World Map Cards
    var settingsTeamMapWorldCards = {
        infinite: false,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 6000,
                settings: "unslick"
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    var teamMapWorldCardsSlick =  $('.worldmap-representants').slick(settingsTeamMapWorldCards);
    $('.worldmap-representants').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        var svgDoc = document.getElementById("svgmap").contentDocument;
        var elem = svgDoc.getElementsByClassName("currenthov");
        for(var i=0; i<elem.length; i++) $(elem[i]).removeClass('currenthov');
        var areaId = $('.worldmap-representants').find("[data-slick-index='" + nextSlide + "']").attr('id').split('-')[1];
        svgDoc.getElementById(areaId).classList.add('currenthov');
    });

    // Slick for Social Wall Cards
    var settingsSocialWallCards = {
        infinite: false,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        slide: '.social',
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    var socialWallCardsSlick =  $('.blocksocialwall').slick(settingsSocialWallCards);

    // Gestion de l'activation / désactivation de slick sur certains éléments
    $(window).on('resize', function() {
        if( $(window).width() < 1200 && !teamMapWorldCardsSlick.hasClass('slick-initialized')) $('.worldmap-representants').slick(settingsTeamMapWorldCards);
        if( $(window).width() <= 1000 && !speciesCardsSlick.hasClass('slick-initialized')) $('.speciesblock-elem .speciesblock-cards').slick(settingsSpeciesCards);
        if( $(window).width() <= 760 && !suggestSpeciesCardsSlick.hasClass('slick-initialized')) $('.suggestspecies-content').slick(settingsSuggestSpeciesCards);
        if( $(window).width() <= 576 && !servicesCardsSlick.hasClass('slick-initialized')) $('.services-list').slick(settingsServicesCards);
        if( $(window).width() <= 576 && !commitCardsSlick.hasClass('slick-initialized')) $('.commitments-cards').slick(settingsCommitCards);
        if( $(window).width() <= 576 && !newsCardsSlick.hasClass('slick-initialized')) $('.newsflexbox').slick(settingsNewsCards);
        if( $(window).width() <= 576 && !keysCardsSlick.hasClass('slick-initialized')) $('.keynumbersblock-numbers').slick(settingsKeysCards);
        if( $(window).width() <= 576 && !socialWallCardsSlick.hasClass('slick-initialized')) $('.blocksocialwall').slick(settingsSocialWallCards);
    });
}

// Fonction qui permet de filtrer les produits
function updateProductList() {
    var currentUrl = window.location.href;
    var filters = '';
    if(currentUrl.indexOf('?filter_products=') !== -1) currentUrl = currentUrl.split('?')[0];
    $('.rangepage-subcategories input').each(function() {
        if($(this).prop('checked')) filters = filters + $(this).val() + ',';
    });
    var url = currentUrl + '?filter_products=' + filters;
    window.location = url;
}

// Fonction qui permet d'initialiser les cartes Leaflet
function initLeafletMap() {
    if($('#mapcontact').length != 0) {
        var latitude = $('#mapcontact').attr('data-latitude');
        var longitude = $('#mapcontact').attr('data-longitude');
        var centerCoords = { lat: latitude, lng: longitude };
        var zoom = 16;
        var zoomLimits = {
            min: 10,
            max: 18
        };
        var tileLayer2D = 'https://api.mapbox.com/styles/v1/gingerminds/ck5cmgcim484s1cofg93usjxq/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ2luZ2VybWluZHMiLCJhIjoiY2p3MXRteGhoMDA1azRhbzU4Njh6eWxociJ9.ZfaRUBFMImXnS2mCjC2BTg';
        window.layer2D = L.tileLayer(tileLayer2D, {
            minZoom: zoomLimits.min,
            maxZoom: zoomLimits.max,
            //detectRetina: true,
            reuseTiles: true
        });

        var mapcontact = L.map('mapcontact', {
            center: centerCoords,
            zoom: zoom,
            minZoom: zoomLimits.min,
            maxZoom: zoomLimits.max,
            touchZoom: true,
            keyboard: false,
            attributionControl: false,
            scrollWheelZoom: false,
            trackResize: true,
            fadeAnimation: true,
            zoomAnimation: true,
            layerControl: false,
            layers: [layer2D]
        });

        var myIcon = L.divIcon({
            className: 'icon-map',
            iconSize: [32, 45],
            iconAnchor: [16, 45]
        });

        var marker = L.marker([latitude, longitude], {icon: myIcon}).addTo(mapcontact);
    }

    if($('#factoriesMap').length != 0) {
        var latitude = $('#factoriesMap').parent().parent().attr('data-latitude');
        var longitude = $('#factoriesMap').parent().parent().attr('data-longitude');
        var centerCoords = { lat: latitude, lng: longitude };
        var zoom = 7;
        var zoomLimits = {
            min: 2,
            max: 18
        };
        var tileLayer2D = 'https://api.mapbox.com/styles/v1/gingerminds/ck5cmgcim484s1cofg93usjxq/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ2luZ2VybWluZHMiLCJhIjoiY2p3MXRteGhoMDA1azRhbzU4Njh6eWxociJ9.ZfaRUBFMImXnS2mCjC2BTg';
        window.layer2D = L.tileLayer(tileLayer2D, {
            minZoom: zoomLimits.min,
            maxZoom: zoomLimits.max,
            reuseTiles: true
        });

        var mapFactories = L.map('factoriesMap', {
            center: centerCoords,
            zoom: zoom,
            minZoom: zoomLimits.min,
            maxZoom: zoomLimits.max,
            touchZoom: true,
            keyboard: false,
            attributionControl: false,
            scrollWheelZoom: false,
            trackResize: true,
            fadeAnimation: true,
            zoomAnimation: true,
            layerControl: false,
            layers: [layer2D]
        });

        // Ajout des cluster des usines
        var index = 0;
        $('.blockfactories-listwrapper ul li').each(function() {
            var latitude = parseFloat($(this).attr('data-latitude'));
            var longitude = parseFloat($(this).attr('data-longitude'));
            if($(this).children('p').html().length >= 58 || $(this).children('h3').children('strong').html().length > 16) {
                if($(this).children('h3').children('strong').html().length < 20) {
                    var html = "<div class='cluster-infos medium'><span class='close'>Fermer la fenêtre</span><strong>"+ $(this).children('h3').children('strong').html() + "</strong><p>"+ $(this).children('p').html() + "</p></div>";
                }else {
                    var html = "<div class='cluster-infos large'><span class='close'>Fermer la fenêtre</span><strong>"+ $(this).children('h3').children('strong').html() + "</strong><p>"+ $(this).children('p').html() + "</p></div>";
                }
            }else {
                var html = "<div class='cluster-infos'><span class='close'>Fermer la fenêtre</span><strong>"+ $(this).children('h3').children('strong').html() + "</strong><p>"+ $(this).children('p').html() + "</p></div>";
            }

            var myIcon = L.divIcon({
                className: 'icon-map' + ' factory-' + index,
                iconSize: [30, 40],
                iconAnchor: [15, 38],
                html: html
            });

            var marker = L.marker([latitude, longitude], {icon: myIcon}).on('click', showHideClusterInfos).addTo(mapFactories);
            index++;
        });
    }
}

// Fonction qui permet d'afficher les infos d'une usine sur la carte, au click sur un cluster
function showHideClusterInfos() {
    if($('#factoriesMap .' + this._icon.classList[2]).children('.cluster-infos').hasClass('show')) {
        $('#factoriesMap .is-selected').removeClass('is-selected');
        $('.blockfactories-listwrapper ul li.current').removeClass('current');
        $('#factoriesMap .' + this._icon.classList[2]).children('.cluster-infos').removeClass('show');
    }else {
        $('#factoriesMap .cluster-infos').removeClass('show');
        $('#factoriesMap .is-selected').removeClass('is-selected');
        $('.blockfactories-listwrapper ul li.current').removeClass('current');
        $('.blockfactories-listwrapper ul li.list-' + this._icon.classList[2]).addClass('current');
        $('#factoriesMap .' + this._icon.classList[2]).addClass('is-selected');
        $('#factoriesMap .' + this._icon.classList[2]).children('.cluster-infos').addClass('show');
    }
}

// Fonction qui permet d'afficher les infos d'une usine sur la carte, au click d'une adresse
function showClusterListInfos() {
    var className = $(this).attr('class').split('list-')[1];
    if($(this).hasClass('current')) {
        $('#factoriesMap .is-selected').removeClass('is-selected');
        $(this).removeClass('current');
        $('#factoriesMap .cluster-infos').removeClass('show');
    }else {
        $('#factoriesMap .cluster-infos').removeClass('show');
        $('#factoriesMap .is-selected').removeClass('is-selected');
        $('.blockfactories-listwrapper ul li.current').removeClass('current');
        $(this).addClass('current');
        $('#factoriesMap .' + className).addClass('is-selected');
        $('#factoriesMap .' + className).children('.cluster-infos').addClass('show');
    }
}

// Fonction qui permet d'afficher les modales pour afficher le formulaire de téléchargement des fiches techniques
function showModalDownloadForm(e) {
    e.preventDefault();
    var id = $(this).attr('id').split('-')[1];
    $('#modal-download-' + id).addClass('show');
    $('html, body').css('overflow', 'hidden');
    $('.modal-close').click(function() {
        $('.modal').removeClass('show');
        $('html, body').removeAttr('style');
    })
}

// Fonction qui permet de tester les formulaires
function testerFormulaire(e) {
    e.preventDefault();

    var fromId = $(this).parent().parent().parent().parent().attr('id');

    // vérification des saisies utilisateurs
    nbChampVide = 0;
    $('.error-msg').fadeOut(200);
    $('select.error').removeClass('error');
    $('select.valid').removeClass('valid');
    $('input.error').removeClass('error');
    $('input.valid').removeClass('valid');

    // On vérifie que l'utilisateur a coché les cases RGPD
    if($('#' + fromId+ ' input[name="rgpd[]"]').prop( "checked" ) == false) {
        $('#' + fromId+ ' input[name="rgpd[]"]').parent().parent().parent().parent().children('.error-msg').fadeIn(300);
        nbChampVide++;
    }

    // On vérifie s'il y a des select list obligatoire
    $('#' + fromId+ ' select.wpcf7-validates-as-required').each(function () {
        if($(this).val() == '' || $(this).val() == ' ') {
            $(this).addClass('error');
            $(this).parent().children('.error-msg').fadeIn(300);
            nbChampVide++;
        }else {
            $(this).addClass('valid');
        }
    });

    // On vérifie la saisie des champs obligatoire
    $('#' + fromId+ ' .form input.wpcf7-validates-as-required, #' + fromId+ ' .wpcf7-form input.wpcf7-validates-as-required').each(function() {
        if($(this).val() == "" || $(this).val() == " ") { // On vérifie que le champ n'est pas vide
            nbChampVide++;
            $(this).addClass('error');
            $(this).parent().children('.error-msg').fadeIn(300);
        }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
            var name = $(this).attr('name');
            if(name == 'email') {
                var regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
                if (regex.test($(this).val()) == false) {
                    nbChampVide++;
                    $(this).addClass('error');
                    $(this).parent().children('.error-msg').fadeIn(300);
                }else {
                    $(this).addClass('valid');
                }
            }else {
                $(this).addClass('valid');
            }
        }
    });

    // On vérifie que les textarea requis sont renseignés
    $('#' + fromId+ ' .form textarea.wpcf7-validates-as-required, #' + fromId+ ' .wpcf7-form textarea.wpcf7-validates-as-required').each(function() {
        if($(this).val() == "" || $(this).val() == " ") { // On vérifie que le champ n'est pas vide
            nbChampVide++;
            $(this).addClass('error');
            $(this).parent().children('.error-msg').fadeIn(300);
        }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
            $(this).addClass('valid');
        }
    });

    if(nbChampVide !== 0) {
        $("html, body").animate({ scrollTop: $('.error').offset().top - 125 }, 500);
    }else {
        $.ajax({
            type: "POST",
            url: $('#' + fromId+ ' .form, #' + fromId+ ' .wpcf7-form').attr('action'),
            data: $('#' + fromId+ ' .form, #' + fromId+ ' .wpcf7-form').serialize(), // serializes the form's elements.
            beforeSend: function() {
                $('#' + fromId+ ' .contentform').before('<img src="https://www.raiz-up.com/wp-content/themes/raizup/images/loader.gif" class="loader" alt="loader" width="200" height="200" />');
                $('#' + fromId+ ' .contentform').addClass('disabled');
            },
            success: function(data) {
                $('.loader').fadeOut(200);
                $('#' + fromId+ ' .contentform').fadeOut(400, function() {
                    $('#' + fromId+ ' .intro').hide();
                    $('#' + fromId+ ' .validation-msg').fadeIn(400);
                });
            },
            error: function(xhr, textStatus) {

            }
        });
    }
}

// Fonctions qui permettent d'animer la carte des commerciaux en SVG
function teamWorldMapEvents() {
    if($('#svgmap').length !== 0) {
        var svg = document.getElementById("svgmap");
        var svgDoc = svg.contentDocument;
        var elem = svgDoc.getElementsByClassName("area");
        for(var i=0; i<elem.length; i++) {
            if($(window).width() >= 1200) {
                elem[i].addEventListener('mouseover', showHideRepreCardOnHover, false);
            }else {
                elem[i].addEventListener('click', showHideRepreCard, false);
                svgDoc.getElementById('latinamerica').classList.add('currenthov');
            }
        }
    }
}

// Fonction qui permet d'afficher les cards des commerciaux
function showHideRepreCard(e) {
    var areaId = $(e.target).attr('id');
    if($(e.target).attr('class').indexOf('currenthov') === -1) {
        var svgDoc = document.getElementById("svgmap").contentDocument;
        var elem = svgDoc.getElementsByClassName("currenthov");
        for(var i=0; i<elem.length; i++) $(elem[i]).removeClass('currenthov');
        $(e.target).addClass('currenthov');
        if($(window).width() >= 1200) {
            $('.cardrepres').removeClass('show');
            $('#representant-' + areaId).addClass('show');
        }else {
            var slider = $('.worldmap-representants');
            var repIndex = $('#representant-' + areaId).attr('data-slick-index');
            slider[0].slick.slickGoTo(parseInt(repIndex));
        }
    }else {
        $(e.target).removeClass('currenthov');
        $('#representant-' + areaId).removeClass('show');
    }

    $('.cardrepres-close').click(function() {
        var svgDoc = document.getElementById("svgmap").contentDocument;
        var elem = svgDoc.getElementsByClassName("currenthov");
        for(var i=0; i<elem.length; i++) $(elem[i]).removeClass('currenthov');
        $(e.target).removeClass('currenthov');
        $('#representant-' + areaId).removeClass('show');
    });
}

function showHideRepreCardOnHover(e) {
    var areaId = $(e.target).attr('id');
    if($(e.target).attr('class').indexOf('currenthov') === -1) {
        var svgDoc = document.getElementById("svgmap").contentDocument;
        var elem = svgDoc.getElementsByClassName("currenthov");
        for(var i=0; i<elem.length; i++) $(elem[i]).removeClass('currenthov');
        $(e.target).addClass('currenthov');
        if($(window).width() >= 1200) {
            $('.cardrepres').removeClass('show');
            $('#representant-' + areaId).addClass('show');
        }else {
            var slider = $('.worldmap-representants');
            var repIndex = $('#representant-' + areaId).attr('data-slick-index');
            slider[0].slick.slickGoTo(parseInt(repIndex));
        }
    }


}

function scrollTopPage() {
    $('html, body').animate({scrollTop: 0}, 1000);
}
$('.footer-cookies a').click(function(e) {
    e.preventDefault();
    tarteaucitron.userInterface.openPanel();
});

$('.player').click(function(e) {
    e.preventDefault();
    $(this).addClass('active');
});

var youtube_tag = document.createElement('script');
youtube_tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('footer')[0];
firstScriptTag.parentNode.insertBefore(youtube_tag, firstScriptTag);

//code pour fancybox + tarteaucitron
// $(".player").fancybox({
//     touch: false,
//     afterLoad:function(e){
//         let src =  e.current.src;
//         let id = src.match('^.*(?:youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*');
//         $('.fancybox-content').html( "<div class='youtube_player oembed_video youtube' videoID='"+id[1]+"' width='100%' height='100%'></div>" );
//         $('.oembed_video').html("<div id='player_"+id[1]+"'></div>");
//         var player = new YT.Player('player_'+id[1], {
//             height: '100%',
//             width: '100%',
//             videoId: id[1],
//         });
//     }
// });

